const Color = {
  PRIMARY: 'rgb(253, 103, 107)',
  SECONDARY: '#1E2D4F',
  DARK: '#000000',
  GREY_3: '#585858',
  GREY_2: '#a1a1a1',
  GREY_1: '#d9d9d9',
  GREY: '#eeeeee',
  WHITE: '#ffffff',
  DIRTY_WHITE: 'rgb(250,250,250)',
  BORDER_COLOR: 'rgb(238, 238, 238)',
  CLICKABLE: '#488cbd',
  DANGER: '#E73D57',
  WHITE_CREAM: 'rgb(249,249,249)',
  DARK_BLUE: 'rgb(33,44,77)',
  MEDIUM_DARK_BLUE: 'rgb(42,55,96)',
  HIGHLIGHT_BLUE: 'rgb(138,178,225)'
}

export default Color
