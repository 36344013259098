import { Color } from '@services/styles'
import logo from '@static/images/logo.png'
import React from 'react'
import { Col, Container, Nav, Row } from 'react-bootstrap'
import footerPaths from './footer-paths-temp.json'
import './footer.scss'

const FooterStart = () => {
  return (
    <Container className="flex-row justify-content-between pt-5 pb-3 footer-top">
      <Row>
        <Col xs={2} style={{ color: Color.WHITE }}>
          <img src={logo} width={'100%'} alt={'logo'} />
        </Col>
        <Col xs={2} />
        {footerPaths.map(i => (
          <Col xs={2} key={i.title}>
            <div style={{ color: Color.WHITE, fontWeight: 600 }}>{i.title}</div>
            <ul>
              {i.children.map(j => (
                <li key={j.title}>
                  <Nav.Item>
                    <Nav.Link href="/">{j.title}</Nav.Link>
                  </Nav.Item>
                </li>
              ))}
            </ul>
          </Col>
        ))}
      </Row>
    </Container>
  )
}

const FooterEnd = () => {
  return (
    <Container className="flex-row justify-content-between pt-3 pb-3 footer-bottom">
      <Nav className="justify-content-start">
        <Nav.Item>
          <Nav.Link href="/" disabled>
            © {new Date().getFullYear()} UAsk.io
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="/">Terms</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="/">Privacy</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="/">Site Map</Nav.Link>
        </Nav.Item>
      </Nav>
      <Nav className="justify-content-end">
        {/*<Nav.Item>*/}
        {/*  <Nav.Link href="/" disabled>*/}
        {/*    <FaFacebook />*/}
        {/*  </Nav.Link>*/}
        {/*</Nav.Item>*/}
        {/*<Nav.Item>*/}
        {/*  <Nav.Link href="/">*/}
        {/*    <FaTwitterSquare />*/}
        {/*  </Nav.Link>*/}
        {/*</Nav.Item>*/}
        {/*<Nav.Item>*/}
        {/*  <Nav.Link href="/">*/}
        {/*    <FaInstagram />*/}
        {/*  </Nav.Link>*/}
        {/*</Nav.Item>*/}
        {/*<Nav.Item>*/}
        {/*  <Nav.Link href="/">*/}
        {/*    <FaYoutube />*/}
        {/*  </Nav.Link>*/}
        {/*</Nav.Item>*/}
      </Nav>
    </Container>
  )
}

const Footer = () => {
  return (
    <footer style={{ backgroundColor: Color.SECONDARY }}>
      {/*<FooterStart />*/}
      <FooterEnd />
    </footer>
  )
}

export default Footer
