import routes from '@components/header/routes'
import { useWindowScroll } from '@services/hooks'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import startCase from 'lodash/startCase'
import React from 'react'
import { Button, Container, Nav, Navbar } from 'react-bootstrap'
import logo from 'static/images/logo.png'
import './menu.scss'

const colorChangeOffset = 60

const Header = () => {
  const pageOffset = useWindowScroll()
  const isScrolled = pageOffset > colorChangeOffset
  return (
    <Navbar
      expand="lg"
      className={`header position-fixed w-100  ${
        isScrolled ? 'bg-light' : 'none'
      }`}
      variant={isScrolled ? 'light' : 'dark'}
      style={{ zIndex: 99999 }}
    >
      <Container className="justify-content-between">
        <Navbar.Brand>
          <Link to="/">
            <StaticImage
              src="../../static/images/logo.png"
              height={40}
              placeholder="blurred"
              alt={'logo'}
              className="d-inline-block align-top"
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse aria-controls="basic-navbar-nav">
          <Nav className="justify-content-center flex-grow-1">
            <Nav className="navbar-item">
              {routes.map(({ key }) => (
                <Link to={'/' + key} className="nav-link no-style" key={key}>
                  {startCase(key)}
                </Link>
              ))}
            </Nav>
            <Button
              href="https://app.uask.io"
              target="_blank"
              className="login-button text-white"
            >
              Login
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    // <Layout.Header
    //   className={isScrolled ? 'scrolled' : 'none'}
    //   style={{
    //     zIndex: 999,
    //     backgroundColor: isScrolled ? Color.WHITE : 'transparent',
    //     color: Color.WHITE,
    //     transition: '0.2s linear all',
    //     position: 'fixed',
    //     width: '100%'
    //   }}
    // >
    //   <FlexContainer justifyContent={'center'} style={{ flex: 1 }}>
    //     <FlexContainer style={{ maxWidth, flex: 1 }}>
    //       <img src={logo} style={{ height: 40 }} alt={'logo'} />
    //       <MenuComponent isScrolled={isScrolled} />
    //       <Button shape="round" style={{ color: Color.WHITE }}
    // type="primary"> Login </Button> </FlexContainer> </FlexContainer>
    // </Layout.Header>
  )
}

export default Header
