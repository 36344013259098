interface RouteProps {
  key: string
}

const routes: RouteProps[] = [
  { key: 'our-data' },
  { key: 'market-research' },
  { key: 'our-platform' },
  { key: 'reports' }
]

export default routes
