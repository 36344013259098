import Footer from '@components/footer'
import Header from '@components/header'
import '@services/styles/base.scss'
import React from 'react'
import { Helmet } from 'react-helmet'

const BaseContainer = ({ children, withPadding = false }) => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>UAsk.io</title>
      </Helmet>
      <Header />
      {withPadding && <div style={{ height: 72, width: '100%' }} />}
      <main>{children}</main>
      <Footer />
    </div>
  )
}

export default BaseContainer
